import axios from "axios";
import Cookies from 'universal-cookie';

const instance = axios.create({
	baseURL : process.env.REACT_APP_API
});

instance.interceptors.request.use(
	async (config: any) => {
        let cookies = new Cookies();
		const channelId = process.env.REACT_APP_ID
		if (channelId) {
			config.headers['ChannelId'] = channelId
		}
		return config
	}
)

instance.interceptors.response.use( 
	function (response: any) {
		return Promise.resolve(response)
	},
	function (error: any) {
        return Promise.reject(error);
    }
)

export default instance;