import React, { useEffect, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Mensajes.css'
import TableHeader from '../../components/TableHeader/TableHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Tab from './Tab/Tab';
import Chats from './Chats/Chats';
import { Client } from "@stomp/stompjs";

const Mensajes = (props: any) => {

    const tabs = [
        {
            id: 1,
            label: 'Chats',
            icon: 'Chat',
            click: () => {
                setTabSelected('Chats')
                navigate('#Chats')
            },
            component: <Chats/>
        },
        {
            id: 2,
            label: 'Filtro',
            icon: 'filter_list',
            click: () => {
                setTabSelected('Filtro')
                navigate('#Filtro')
            },
            component: null
        }
    ]

    const { hash }: any = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    const [tabSelected, setTabSelected] = useState<any>(null);
    const [messages, setMessages] = useState<any>(null);

    useEffect(() => {
        setTabSelected(hash.substring(1).replace(/%20/g, " "))
    }, [hash])

    useEffect(() => {
        let stompClient: Client | null = null;
    
        const connectWebSocket = () => {
            stompClient = new Client({
                brokerURL: process.env.REACT_APP_API_WS,
                reconnectDelay: 5000, // Auto-reconnect every 5 seconds
                debug: (str) => console.log(str),
                onConnect: () => {
                    console.log("Connected to WebSocket");
        
                    // Subscribe to the topic for receiving updates
                    stompClient?.subscribe("/topic/messages", (message) => {
                        setMessages((prev: any) => [...prev, message.body]);
                    });
                },
                onStompError: (frame) => {
                    console.error("STOMP error:", frame.headers["message"]);
                },
            });
    
          stompClient.activate();
        };
    
        connectWebSocket();
    
        // Cleanup function
        return () => {
            if (stompClient) {
                stompClient.deactivate();
            }
        };
    }, []);

    console.log(messages)

    useEffect(() => {
        navigate('#Chats')
    }, [])

    return (
        <div className='mensajes-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='mensajes-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <div className='mensajes-container-main-content'>
                    <h2 className='mensajes-container-main-content-title'>{props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name}</h2>
                    <div className='mensajes-container-main-content-section'>
                        <div className='mensajes-container-main-content-section-tabList'>
                            {tabs?.map((tab: any) => (
                                <Tab
                                    icon={tab?.icon}
                                    label={tab?.label}
                                    selectTab={() => tab?.click()}
                                    tabSelected={tab?.label === tabSelected}
                                />
                            ))}
                        </div>
                        {tabs?.filter((a: any) => a.label === tabSelected)[0]?.component}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mensajes;