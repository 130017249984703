
import React from 'react'
import './ChatBox.css'

const ChatBox = (props: any) => {

    return (
        <div 
            className='chatBox-container'
            style={{ backgroundColor: props.chatSelected ? 'var(--gray100)' : 'transparent'}}
            onClick={props.selectChat}
        >
            <div className='chatBox-container-main'>
                <div className='chatBox-container-main-top'>
                    <b className='chatBox-container-main-top-numero bold medium'>{props.chat?.numero}</b>
                    <b className='chatBox-container-main-top-fecha regular small'>{props.chat?.fecha}</b>
                </div>
                <div className='chatBox-container-main-bot'>
                    <span className="material-symbols-rounded" style={{ color: props.chat?.estado === 'READ' ? 'var(--primary500)' : 'var(--gray300)' }}>{props.chat?.estado === 'SENT' ? 'check' : 'done_all'}</span>
                    <b className='chatBox-container-main-bot-ultimoMensaje regular medium'>{props.chat?.ultimoMensaje}</b>
                    {props.chat?.mensajesNoLeidos > 0 && <div className='chatBox-container-main-bot-mensajesNoLeidos'>
                        <b className='chatBox-container-main-bot-mensajesNoLeidos-number'>{props.chat?.mensajesNoLeidos}</b>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default ChatBox;