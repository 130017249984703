
import React from 'react'
import './ChatBubble.css'

const ChatBubble = (props: any) => {

    const divideText = (text: string, searchText: string) => {
        const words: any = text.split(" ");
        const highlight = searchText?.length >= 2 ? searchText : null;
        return words?.map((word: any, index: any) => (
            word?.toLowerCase()?.includes(highlight?.toLowerCase()) ? (
                <div className="chatBubble-container-mensaje-word">
                    <b
                        key={index}
                        className="chatBubble-container-mensaje-word-text regular medium"
                        style={{ 
                            backgroundColor: props.occurrenceSelected ? 'var(--gray700)' : 'var(--successPrimary)',
                            color: 'var(--white)'
                        }}
                    >
                        {word}
                    </b>
                    &nbsp;
                </div>
            ) : (
                <div className="chatBubble-container-mensaje-word">
                    <b
                        key={index}
                        className="chatBubble-container-mensaje-word-text regular medium"
                        style={{ color: props.reciever ? 'var(--gray700)' : 'var(--white)' }}
                    >
                        {word}
                    </b>
                    &nbsp;
                </div>
            )
        ));
    };

    return (
        <div 
            className='chatBubble-container'
            style={{
                marginLeft: props.reciever ? 10 : 'auto',
                marginRight: props.reciever ? 0 : 10,
                alignItems: props.reciever ? 'flex-start' : 'flex-end'
            }}
        >
            <div 
                className='chatBubble-container-mensaje'
                style={{ backgroundColor: props.reciever ? 'var(--gray50)' : 'var(--primary500)' }}
            >
                {divideText(props.mensaje, props.searchText)}
            </div>
            <b className='chatBubble-container-fecha light small'>{props.fecha}</b>
        </div>
    );
}

export default ChatBubble;