
import React from 'react'
import './Tab.css'

const Tab = (props: any) => {

    return (
        <div 
            className='tab-container'
            onClick={props.selectTab}
        >
            <span className="material-symbols-rounded" style={{ color: props.tabSelected ? 'var(--primary200)' : 'var(--white)' }}>{props.icon}</span>
            <span className='tab-container-toolTip'>{props.label}</span>
        </div>
    );
}

export default Tab;