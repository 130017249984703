
import React, { useState } from 'react'
import './Chats.css'
import ChatBox from './ChatBox/ChatBox'
import ChatSection from './ChatSection/ChatSection'

const Chats = (props: any) => {

    const [search, setSearch] = useState<string>('')
    const [selectedChat, setSelectedChat] = useState<any>(null)

    const chats = [
        {
            id: 1,
            numero: '+54 9 11 4444-8888',
            ultimoMensaje: 'Hola buenos días quería saber sobre el seguro de mi auto',
            fecha: '2024-11-20 12:43:00',
            estado: 'RECIEVED',
            mensajesNoLeidos: 2,
        },
        {
            id: 2,
            numero: '+54 9 11 9999-3333',
            ultimoMensaje: 'Si me gustaría contratar el plan de seguros',
            fecha: '2024-11-19 18:34:00',
            estado: 'SENT',
            mensajesNoLeidos: 5,
        },
        {
            id: 3,
            numero: '+54 9 11 7777-4444',
            ultimoMensaje: 'Hola, como están?',
            fecha: '2024-11-16 12:43:00',
            estado: 'READ',
            mensajesNoLeidos: 0,
        }
    ]

    const currentChat = {
        numero: '+54 9 11 4444-8888',
        conversacion: [
            {
                mensaje: 'Hola, como están?',
                fecha: '2024-11-16 12:43:00',
                reciever: true
            },
            {
                mensaje: 'Quisiera saber más sobre....',
                fecha: '2024-11-16 12:43:00',
                reciever: true
            },
            {
                mensaje: 'Hola (Nombre), ¿en que podemos ayudarte?',
                fecha: '2024-11-16 12:43:00',
                reciever: false
            },
            {
                mensaje: 'Si me gustaría contratar el plan de seguros...',
                fecha: '2024-11-16 12:43:00',
                reciever: true
            },
            {
                mensaje: 'iuhasdfoiuasdhfoiuh adosiufh aosidufh oasiudhf oiuashdf iuoashd foiabnsdiof nawipefn aipwefnipa uwdnfpi jasndpjfknas pidjfnaspijdfnpi jasdnfpij asndfpij asndpifj naspdijfna psijdfnpajks dnfpkjawenfpijawnefpj anwdpijf naspjidf napisjdn fpijasdnfipjasndpifjnaw epifnapwijefn paojwdfn oan',
                fecha: '2024-11-16 12:43:00',
                reciever: false
            }
        ]
    }

    return (
        <div className='chats-container'>
            <div className='chats-container-hub'>
                <div className='chats-container-hub-title'>
                    <h5 className='chats-container-hub-title-text'>Chats</h5>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', marginRight: 10 }}>edit_square</span>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primary500)' }}>tune</span>
                </div>
                <div className='chats-container-hub-searcher'>
                    <input
                        className='chats-container-hub-searcher-input'
                        placeholder='Buscar...'
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                    />
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray300)' }}>search</span>
                </div>
                <div className='chats-container-hub-chats'>
                    {chats?.map((chat: any) => (
                        <ChatBox
                            chat={chat}
                            selectChat={() => setSelectedChat(chat?.id)}
                            chatSelected={chat?.id === selectedChat}
                        />
                    ))}
                </div>
            </div>
            <div className='chats-container-chat'>
                <ChatSection currentChat={currentChat}/>
            </div>
        </div>
    );
}

export default Chats;